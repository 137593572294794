var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{staticClass:"mb-1",staticStyle:{"min-width":"98%","border-radius":"5px !important"}},[_c('v-expansion-panel-header',{staticClass:"pa-0 pr-2",staticStyle:{"max-height":"50px !important","border-top-left-radius":"5px","border-bottom-left-radius":"5px"}},[_c('div',{staticClass:"mr-3 card-header",style:(("background-color: " + (_vm.course.color) + ";  border-top-left-radius: 5px; border-bottom-left-radius: 5px;"))},[_c('p')]),_c('div',{style:(("color: " + (_vm.$vuetify.theme.dark ? "" :"#474747") + "}"))},[_c('h3',[_vm._v(_vm._s(_vm.course.courseCode))])]),(_vm.warning.length > 0)?_c('warning',{attrs:{"warning-text":"One of the selected section is not be open for enrollment at the moment, but might open in the future."}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.$vuetify.theme.dark ? "" :"#474747","max-width":"40","max-height":"40"},on:{"click":function($event){return _vm.addOrRemoveConflictCourse({code: _vm.course.courseCode } )}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(_vm.isConflict)?_c('v-icon',[_vm._v("mdi-calendar-alert")]):_c('v-icon',[_vm._v("mdi-calendar-check")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.isConflict ? 'Conflicts are allowed with this course' : "Conflicts are not allowed with this course")+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#474747","max-width":"40","max-height":"40"}},'v-btn',attrs,false),on),[_c('v-dialog',{attrs:{"scrollable":"","width":"825px"},on:{"input":_vm.atInput},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-pencil-box-outline")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('course-section-picker',{ref:"popUp",attrs:{"code":_vm.course.courseCode},on:{"done":function($event){_vm.dialog = false}}})],1)],1)]}}])},[_c('span',[_vm._v("Edit timeslots")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#474747","max-width":"40","max-height":"40","icon":""},on:{"click":function($event){return _vm.deleteCourse({ code: _vm.course.courseCode })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Remove course")])])],1),_c('v-expansion-panel-content',[_c('hr',{staticClass:"mb-1"}),_c('div',{staticClass:"pa-3"},_vm._l((_vm.meetingSections),function(section,code){return _c('div',{key:code},[_c('v-row',[_c('div',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(code)+" "),(_vm.warning.includes(code))?_c('warning',{attrs:{"warning-text":("Section " + code + " is not currently open for enrollment.")}}):_vm._e()],1)]),_vm._l((section),function(time){return _c('v-row',{key:("" + (time.day) + (time.start)),staticClass:"ml-2",staticStyle:{"font-size":"small"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('p',[_vm._v(" "+_vm._s(_vm.getProperDayName(time.day))+" ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.getFormattedTime(time.start, time.end))+" ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(time.location)+" ")])])],1)})],2)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }