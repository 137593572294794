var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('OverwriteLockedSectionPopup',{on:{"Proceed":_vm.autoResolveConflict,"Cancel":_vm.clearTempVars}}),_c('v-toolbar',{staticStyle:{"z-index":"1"},attrs:{"color":_vm.course.color,"dark":""}},[_c('v-toolbar-title',{staticClass:"text-wrap ml-2"},[_vm._v(" "+_vm._s(_vm.course.courseCode)+" "+_vm._s(_vm.course.name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.onClickDone}},[_vm._v("Done")])],1),_c('smooth-scrollbar',[_c('v-card-text',{attrs:{"height":"600px"}},[_c('v-list',{attrs:{"rounded":"","subheader":"","two-line":"","flat":""}},_vm._l((_vm.activities),function(meetingSections,activityType){return _c('v-container',{key:activityType},[(meetingSections.length > 0)?_c('div',[_c('v-radio-group',{staticStyle:{"top-margin":"0px"},attrs:{"mandatory":false},model:{value:(_vm.selectedMeetingSections[activityType]),callback:function ($$v) {_vm.$set(_vm.selectedMeetingSections, activityType, $$v)},expression:"selectedMeetingSections[activityType]"}},[(meetingSections.length > 0)?_c('v-subheader',{staticClass:"activity-header"},[_vm._v(_vm._s(activityType)+"s")]):_vm._e(),_c('v-row',{staticClass:"activity-label"},[_c('v-col',[_c('h4',{staticStyle:{"margin-left":"70px"}},[_vm._v("Activity")])]),_c('v-col',[_c('h4',{staticStyle:{"margin-left":"60px"}},[_vm._v("Time")])]),_c('v-col',[_c('h4',{staticStyle:{"margin-left":"70px"}},[_vm._v("Location")])]),_c('v-col',{staticStyle:{"text-align":"center"}},[_c('h4',[_vm._v("Instructor")])])],1),_c('v-divider',{staticClass:"activity-divider"}),_c('v-list-item-group',_vm._l((meetingSections),function(meetingSection){return _c('v-list-item',{key:meetingSection.sectionCode,staticStyle:{"margin-bottom":"0px"},on:{"click":function($event){return _vm.setMeetingSection(
                      meetingSection.sectionCode,
                      activityType
                    )}}},[_c('v-list-item-action',[_c('v-radio',{attrs:{"value":meetingSection.sectionCode}})],1),_c('v-list-item-content',{staticClass:"content-no-padding"},[_c('v-row',[_c('v-col',{staticClass:"contain",attrs:{"cols":"2"}},[_c('v-row',{staticClass:"center-vertical"},[_c('v-col',[_c('v-list-item-title',[(_vm.getWarningSections.some(function (x){ return x.code === _vm.course.courseCode && x.sectionCode === meetingSection.sectionCode; }))?_c('warning'):_vm._e(),_vm._v(_vm._s(meetingSection.sectionCode)+" ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[(meetingSection.times.length > 0)?_c('div',_vm._l((meetingSection.times),function(time){return _c('v-row',{key:("" + (time.day) + (time.start))},[_c('v-col',[(
                                  _vm._checkConflict(
                                    time.day,
                                    time.start,
                                    time.end,
                                    _vm.timetableSelectedMeetingSections[
                                      activityType
                                    ]
                                  ) != null
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('div',_vm._g({staticClass:"conflicting-time-orange"},on),[_vm._v(" "+_vm._s(_vm.getProperDayName(time.day).slice(0, 3))+" "+_vm._s(_vm.getFormattedTime(time.start, time.end))+" ")])]}}],null,true)},_vm._l((_vm._checkConflict(
                                    time.day,
                                    time.start,
                                    time.end,
                                    _vm.timetableSelectedMeetingSections[
                                      activityType
                                    ]
                                  )),function(conflictSection){return _c('div',{key:("" + (conflictSection.courseCode) + (conflictSection.sectionCode))},[_vm._v(" Conflicts with "+_vm._s(conflictSection.conflictString)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.getProperDayName(time.day))+" "+_vm._s(_vm.getFormattedTime(time.start, time.end))+" ")])],1)],1)}),1):_c('v-row',[_c('v-col',[_c('div',{staticClass:"ml-10"},[_vm._v("Asynchronous")])])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[(meetingSection.times.length > 0)?_c('div',_vm._l((meetingSection.times),function(time){return _c('v-row',{key:("" + (time.day) + (time.start))},[_c('v-col',[(time.location.length > 0)?_c('div',[_vm._v(" "+_vm._s(time.location)+" ")]):(meetingSection.method !== 'INPER')?_c('div',[_vm._v(" Online ")]):_c('div',[_vm._v(" TBA ")])])],1)}),1):_c('v-row',{attrs:{"cols":"2"}},[_c('v-col',[_c('div',[_vm._v(" Online ")])])],1)],1),_c('v-col',{staticClass:"contain"},[(
                            activityType === 'lecture' &&
                              meetingSection.instructors.length != 0
                          )?_c('div',{staticClass:"center-vertical",staticStyle:{"text-align":"center","width":"88%","text-wrap":"break-word"}},[_vm._v(" "+_vm._s(meetingSection.instructors[0])+" ")]):_c('div',{staticClass:"center-vertical",staticStyle:{"text-align":"center","width":"88%"}},[_vm._v(" TBA ")])])],1)],1)],1)}),1)],1)],1):_vm._e()])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }